import { FC, useState } from "react";
import { Stack } from "@mui/material";
import { Button, InputField } from "@vilocnv/allsetra-core";
import { isEmpty } from "lodash";
import { useAppDispatch } from "hooks";
import { deviceRawCommandThunk } from "app/features";
import { useParams } from "react-router-dom";

const DeviceRawCommand: FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const deviceId = params.id;

  const [payloadValue, setPayloadValue] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onRawCommandHandler = async () => {
    if (isEmpty(payloadValue)) return;

    setIsSubmitting(true);

    await dispatch(
      deviceRawCommandThunk({ deviceId, data: { payload: payloadValue } })
    );

    setIsSubmitting(false);
  };

  return (
    <main style={{ height: "100vh" }}>
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        height={"100%"}
        gap={3}
      >
        <InputField
          label={"Payload"}
          type={"text"}
          name="payload"
          value={payloadValue}
          onChange={(e) => setPayloadValue(e.target.value)}
        />
        <Button
          size={"medium"}
          disabled={isEmpty(payloadValue)}
          loading={isSubmitting}
          onClick={onRawCommandHandler}
        >
          Submit
        </Button>
      </Stack>
    </main>
  );
};

export default DeviceRawCommand;
