import { FC } from "react";
import { Table, types } from "@vilocnv/allsetra-core";

// DATA
import { useAppDispatch } from "hooks";
import { ALARM_COMMENTS_TABLE_COLUMNS } from "app/data/constants";
import {
  deleteCommentFromAlarmThunk,
  useGetAlarmAssociatedCommentsQuery,
} from "app/features";

export interface AlarmCommentsProps {
  alarm: types.IAlarm;
}

const AlarmComments: FC<AlarmCommentsProps> = ({ alarm }) => {
  const dispatch = useAppDispatch();

  const { data, isFetching, isLoading, refetch } =
    useGetAlarmAssociatedCommentsQuery(alarm?.object?.uniqueId || "");

  const handleDeleteComment = async (row: any) => {
    const { type } = await dispatch(
      deleteCommentFromAlarmThunk({
        objectId: alarm?.object?.uniqueId ?? "",
        commentId: row.uniqueId,
      })
    );
    if (type === "alarmDesk/deleteCommentFromAlarmThunk/fulfilled") refetch();
  };

  return (
    <Table
      title="Comments"
      columns={ALARM_COMMENTS_TABLE_COLUMNS}
      data={data?.filter((item: any) => !item.isDeleted) || []}
      cellActions={[
        {
          name: "Delete comment",
          onClick: handleDeleteComment,
        },
      ]}
      progressPending={isFetching || isLoading}
    />
  );
};

export default AlarmComments;
