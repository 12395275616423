import { FC, useState, useCallback, useEffect, useMemo } from "react";
import { Grid } from "@mui/material";
import { Table, types } from "@vilocnv/allsetra-core";
import AlarmExpendableRowCard from "components/cards/AlarmExpendableRowCard/AlarmExpendableRowCard";
import AlarmReportTheftForm from "components/forms/alarmDesk/AlarmReportTheftForm/AlarmReportTheftForm";
import ClearAlarmForm from "components/forms/alarmDesk/ClearAlarmForm/ClearAlarmForm";
import AlarmSendEmailForm from "components/forms/alarmDesk/AlarmSendEmailForm/AlarmSendEmailForm";
import AlarmSendSMSForm from "components/forms/alarmDesk/AlarmSendSMSForm/AlarmSendSMSForm";

// Data
import { useAppDispatch, useAppSelector } from "hooks";
import { ALL_ALARMS_TABLE_COLUMNS } from "app/data/constants";
import {
  disableDeviceImmobilizerThunk,
  postLockAlarmThunk,
  postUnlockAlarmThunk,
  postUnlockAllAlarmsThunk,
  clearObjectData,
  setIsLockedOfAlarm,
  resetAccountGeozones,
  resetAllObjects,
  setActiveAlarmId,
} from "app/features";
import {
  selectAlarmDeskState,
  selectDashboardAccountId,
} from "app/data/selectors";
import AlarmDeskMap from "components/common/Map/AlarmDesk/AlarmDeskMap";
import { omit } from "lodash";
import { SignalRService } from "app/data/services";
import { signalRAlarmsEventsListener } from "app/data/helpers";

interface Props {
  hideActionsRow?: boolean;
  loading: boolean;
  data: Array<any>;
  paginationTotalRows?: number | null;
  mapZoom?: number;
  fromDashboard?: boolean;
}

const AlarmDeskTableGrid: FC<Props> = ({
  hideActionsRow = false,
  loading,
  data,
  paginationTotalRows,
  mapZoom = 8,
  fromDashboard,
}) => {
  const dispatch = useAppDispatch();

  // Global State
  const accountId = useAppSelector(selectDashboardAccountId);
  const { activeAlarmId, specificAlarm } = useAppSelector(selectAlarmDeskState);

  // Local State
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [tableKey, setTableKey] = useState<number>(0);

  // Form Modals States
  const [openSendEmailModal, setOpenSendEmailModal] = useState<boolean>(false);
  const [openSendSMSModal, setOpenSendSMSModal] = useState<boolean>(false);
  const [openClearAlarmModal, setOpenClearAlarmModal] =
    useState<boolean>(false);
  const [openReportTheftModal, setOpenReportTheftModal] =
    useState<boolean>(false);
  const [shouldRenderGeozone, setShouldRenderGeozone] = useState(true);
  const [shouldRenderAccounts, setShouldRenderAccounts] = useState(false);

  const conditionalRowStyles = useMemo(
    () => [
      {
        when: (row: any) =>
          row?.isLocked && row?.lockedBy?.uniqueId !== accountId,
        style: {
          backgroundColor: "#FFF5F5",
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
      {
        when: (row: any) => row?.awayModeEnabled && row?.hasScmService,
        style: {
          backgroundColor: "#F0F6FF",
        },
      },
      {
        when: (row: any) => row?.awayModeEnabled,
        style: {
          backgroundColor: "#F0F6FF",
        },
      },
      {
        when: (row: any) => row?.hasScmService,
        style: {
          backgroundColor: "#FCF8F0",
        },
      },
    ],
    [accountId]
  );

  const toggleSendEmailModal = () => setOpenSendEmailModal(!openSendEmailModal);

  const toggleSendSMSModal = () => setOpenSendSMSModal(!openSendSMSModal);

  const toggleClearAlarmModal = () =>
    setOpenClearAlarmModal(!openClearAlarmModal);

  const toggleReportTheftModal = () =>
    setOpenReportTheftModal(!openReportTheftModal);

  const handleGeneratePDF = useCallback(() => {
    const pdfDownloadLink = document.getElementById("pdfDownloadLink");
    pdfDownloadLink?.click();
  }, []);

  const handleDisableImmobilizer = (deviceId: string) => {
    dispatch(disableDeviceImmobilizerThunk(deviceId));
  };

  const handleExpandableRowExpanded = useCallback(
    (row: types.IAlarm) => {
      return row.isLocked && row.lockedBy?.uniqueId === accountId;
    },
    [accountId]
  );

  const handleExpandableRowDisabled = useCallback(
    (row: types.IAlarm) => {
      return row.isLocked && row.lockedBy?.uniqueId !== accountId;
    },
    [accountId]
  );

  const resetActiveAlarmAssociatedData = () => {
    dispatch(resetAccountGeozones());
    dispatch(resetAllObjects());
    setShouldRenderGeozone(false);
    setShouldRenderAccounts(false);
  };

  const onRowExpandToggled = useCallback(
    async (expanded: boolean, row: types.IAlarm) => {
      const payload = {
        alarmId: row.uniqueId,
        lockedBy: expanded ? { uniqueId: accountId } : null,
      };

      if (expanded) {
        setShouldRenderGeozone(true);

        // If any alarm is already expanded then
        // Firstly we would need to close off that current active alarm
        if (activeAlarmId) {
          const payloadOfAlarmToUnlock = {
            alarmId: activeAlarmId ?? "",
            isLocked: false,
            lockedBy: null,
          };

          dispatch(
            postUnlockAlarmThunk(omit(payloadOfAlarmToUnlock, ["isLocked"]))
          );
        }

        // Now we would need to lock the new opened alarm
        // And set it as an active alarm
        try {
          setIsSubmitting(true);

          const { payload: resPayload }: any = await dispatch(
            postLockAlarmThunk(payload)
          );
          const { data } = resPayload;

          if (resPayload.status === 409 || resPayload.status === 500) {
            dispatch(
              setIsLockedOfAlarm({
                alarmId: row.uniqueId,
                isLocked: data?.isLocked ?? false,
                lockedBy: data?.lockedBy ?? null,
              })
            );

            // To trigger a manual re-render for the table
            setTableKey((value) => value + 1);
          }
        } catch (error) {
          console.log({ error });
        } finally {
          setIsSubmitting(false);
        }
      } else {
        // If an alrm is being closed then we unlock that alarm
        dispatch(postUnlockAlarmThunk(payload));
        dispatch(setActiveAlarmId(null));
        resetActiveAlarmAssociatedData();
      }
    },
    [accountId, activeAlarmId]
  );

  const onClearText = () => {
    dispatch(clearObjectData());
  };

  useEffect(() => {
    dispatch(setActiveAlarmId(null));
    resetActiveAlarmAssociatedData();
    onClearText();

    return () => {
      dispatch(setActiveAlarmId(null));
      dispatch(postUnlockAllAlarmsThunk());
      resetActiveAlarmAssociatedData();
    };
  }, []);

  useEffect(() => {
    SignalRService?.hubConnection?.on("EventRaised", (event) => {
      signalRAlarmsEventsListener(event, dispatch);
    });
  }, []);

  return (
    <div>
      <Grid container pl={fromDashboard ? 2 : 0}>
        <Grid item sm={12} md={12} lg={12} xl={7}>
          <Table
            key={tableKey}
            columns={ALL_ALARMS_TABLE_COLUMNS}
            data={data}
            progressPending={loading}
            pagination={paginationTotalRows ? true : false}
            paginationTotalRows={paginationTotalRows}
            expandableRows
            expandOnRowClicked
            expandableRowsComponent={AlarmExpendableRowCard}
            expandableRowsComponentProps={{
              toggleSendEmailModal: toggleSendEmailModal,
              toggleReportTheftModal: toggleReportTheftModal,
              toggleClearAlarmModal: toggleClearAlarmModal,
              toggleSendSMSModal: toggleSendSMSModal,
              handleDisableImmobilizer: handleDisableImmobilizer,
              hideActionsRow: hideActionsRow,
              isLoading: isSubmitting,
            }}
            expandableRowExpanded={handleExpandableRowExpanded}
            expandableRowDisabled={handleExpandableRowDisabled}
            onRowExpandToggled={onRowExpandToggled}
            conditionalRowStyles={conditionalRowStyles}
          />
        </Grid>
        <Grid item sm={12} md={12} lg={12} xl={5} sx={{ position: "relative" }}>
          <AlarmDeskMap
            center={{ lat: 52.0, lng: 5.301137 }}
            zoom={mapZoom}
            objects={data}
            selectedAlarmId={activeAlarmId}
            shouldRenderGeozone={shouldRenderGeozone}
            setShouldRenderGeozone={setShouldRenderGeozone}
            shouldRenderAccounts={shouldRenderAccounts}
            setShouldRenderAccounts={setShouldRenderAccounts}
          />
        </Grid>
      </Grid>
      <AlarmSendEmailForm
        open={openSendEmailModal}
        onClose={toggleSendEmailModal}
        alarmId={activeAlarmId}
      />
      <AlarmSendSMSForm
        open={openSendSMSModal}
        onClose={toggleSendSMSModal}
        alarmId={activeAlarmId}
        selectedAlarm={specificAlarm}
      />
      <ClearAlarmForm
        open={openClearAlarmModal}
        onClose={toggleClearAlarmModal}
        alarmId={activeAlarmId}
        selectedAlarm={specificAlarm}
      />
      <AlarmReportTheftForm
        open={openReportTheftModal}
        onClose={toggleReportTheftModal}
        alarmId={activeAlarmId}
        generatePDF={handleGeneratePDF}
      />
    </div>
  );
};

export default AlarmDeskTableGrid;
