import { FC, useState } from "react";
import { Stack, useTheme } from "@mui/material";
import {
  FormikCheckbox,
  FormikInputField,
  FormikSelectField,
  Modal,
  ModalProps,
} from "@vilocnv/allsetra-core";
import { useFormikContext } from "formik";
import { IClearAlarm } from "app/data/types";
import { useGetClearAlarmReasonsQuery } from "app/features";
import { ALARM_DELAY_DROPDOWN } from "app/data/constants";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

type Props = Omit<ModalProps, "title" | "children"> & {
  isProcessing: boolean;
};

const InnerForm: FC<Props> = ({ open, onClose, isProcessing, ...rest }) => {
  const theme = useTheme();
  const [isCustomType, setCustomType] = useState<boolean>(false);
  const [delayValue, setDelayValue] = useState<string>("");

  const { resetForm, isSubmitting, isValid, handleSubmit, setFieldValue } =
    useFormikContext<IClearAlarm>();

  // @ts-ignore
  const { data: clearAlarmReasons, isLoading } = useGetClearAlarmReasonsQuery();

  const onCloseHandler = () => {
    onClose();
    resetForm();
  };

  const handleDropDown = (item: any) => {
    if (item === "Custom") setCustomType(true);
    else {
      setCustomType(false);
      setFieldValue("delay", item);
    }
  };

  const calculateMinutesToFutureDate = (futureDate: any) => {
    const currentDate: any = new Date(); // Get today's date
    const targetDate: any = new Date(futureDate?.$d); // Convert the provided future date to a Date object

    // Calculate the difference in milliseconds between the two dates
    const differenceMs = targetDate - currentDate;

    // Convert milliseconds to minutes
    const minutesDifference = Math.floor(differenceMs / (1000 * 60));
    setFieldValue("delay", minutesDifference);
    setDelayValue(String(futureDate?.$d || ""));
  };

  return (
    <Modal
      open={open}
      onClose={onCloseHandler}
      title={"Clear Alarm"}
      primaryBtnProps={{
        type: "submit",
        text: "Clear Alarm",
        loading: isSubmitting || isProcessing,
        disabled: isCustomType && !delayValue ? true : !isValid,
        // @ts-ignore
        onClick: handleSubmit,
      }}
      secondaryBtnProps={{
        text: "Cancel",
        onClick: onCloseHandler,
      }}
      theme={theme}
      {...rest}
    >
      <Stack spacing={0.5}>
        <FormikSelectField
          label="Reason"
          name="reason"
          options={clearAlarmReasons || []}
          loading={isLoading}
          optionLabelKey="reason"
          optionValueKey="uniqueId"
          required
        />
        <FormikInputField
          label="Comment"
          name="comment"
          placeholder="Enter comment"
          multiline
          rows={3}
          required
        />
        <FormikCheckbox
          label="Mark comment as priority comment"
          name="isImportant"
        />
        <FormikCheckbox
          label="Set comment on Account level"
          name="isFromAccount"
        />
        <FormikSelectField
          name="delayType"
          label="Silent this alarm type for"
          placeholder="Select a delay"
          options={ALARM_DELAY_DROPDOWN}
          optionLabelKey={"name"}
          optionValueKey={"value"}
          onChange={handleDropDown}
        />
        {isCustomType && (
          <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateTimePicker"]}>
                <DemoItem label="Select date">
                  <DateTimePicker
                    format="DD/MM/YYYY HH:m"
                    ampm={false}
                    autoFocus
                    minDateTime={dayjs()}
                    name="_delay"
                    className="datePicker"
                    onChange={calculateMinutesToFutureDate}
                  />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
          </>
        )}
      </Stack>
    </Modal>
  );
};

export default InnerForm;
