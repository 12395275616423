import { FC, Fragment } from "react";
import { Box, Stack } from "@mui/material";
import {
  ContentSectionLayout,
  TwoColsLayout,
  FormikDropzone,
  FormikSelectSearchField,
  FormikInputField,
  PageLoader,
  FormikToggleField,
} from "@vilocnv/allsetra-core";

// Data
import { WhiteLabelConfigurationFormProps } from "../index";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectAccountsState } from "app/data/selectors";
import { getAccountsByQueryThunk } from "app/features";

type InnerFormProps = Omit<
  WhiteLabelConfigurationFormProps,
  "specificWhiteLabel"
>;

const InnerForm: FC<InnerFormProps> = ({ loading, isInEdit }) => {
  const dispatch = useAppDispatch();

  const { loading: accountsLoading, allAccounts } =
    useAppSelector(selectAccountsState);

  const handleAccountsSearch = (value: string) => {
    dispatch(
      getAccountsByQueryThunk({
        itemsPerPage: 50,
        page: 1,
        where: [{ field: "name", value, type: 0 }],
      })
    );
  };

  if (loading) {
    return (
      <Box mx={4}>
        <PageLoader />
      </Box>
    );
  }

  return (
    <Box mx={4}>
      <ContentSectionLayout
        title="Account"
        subTitle="Some text to help user understand what this block is responsible for."
      >
        <FormikSelectSearchField
          fetchOnDebounce={handleAccountsSearch}
          label="Select an account"
          name="accountId"
          options={allAccounts ?? []}
          optionLabelKey={"name"}
          optionValueKey={"uniqueId"}
          loading={accountsLoading}
          disabled={isInEdit}
          searchable
          fullWidth
          required
        />
      </ContentSectionLayout>
      <ContentSectionLayout
        title="White label settings"
        subTitle="Some text to help user understand what this block is responsible for."
        hideDivider={!isInEdit}
      >
        <FormikDropzone
          fieldTitle="Background image"
          name="backgroundImage"
          dropzoneOptions={{
            maxFiles: 1,
          }}
          fullWidth
        />
        <Box mt={4}>
          <TwoColsLayout>
            <FormikDropzone
              fieldTitle="Light theme logo"
              name="lightThemeLogo"
              dropzoneOptions={{
                maxFiles: 1,
              }}
              fullWidth
            />
            <FormikDropzone
              fieldTitle="Dark theme logo"
              name="darkThemeLogo"
              dropzoneOptions={{
                maxFiles: 1,
              }}
              fullWidth
            />
            <FormikDropzone
              fieldTitle="Mobile logo"
              name="mobileLogo"
              dropzoneOptions={{
                maxFiles: 1,
              }}
              fullWidth
            />
            <FormikDropzone
              fieldTitle="Favicon"
              name="favIcon"
              dropzoneOptions={{
                maxFiles: 1,
              }}
              fullWidth
            />
          </TwoColsLayout>
        </Box>
        <Box mt={4}>
          <TwoColsLayout>
            <FormikInputField
              label={"Primary color"}
              placeholder={"Primary color"}
              name="primaryColor"
              fullWidth
            />
            <FormikInputField
              label={"Secondary color"}
              placeholder={"Secondary color"}
              name="secondaryColor"
              fullWidth
            />
            <FormikInputField
              label={"Highlight color"}
              placeholder={"Highlight color"}
              name="highlightColor"
              fullWidth
            />
          </TwoColsLayout>
        </Box>
        <Box mt={4}>
          <TwoColsLayout>
            <FormikDropzone
              fieldTitle="Email header image"
              name="emailHeaderImage"
              dropzoneOptions={{
                maxFiles: 1,
              }}
              fullWidth
            />
            <FormikDropzone
              fieldTitle="Email footer image"
              name="emailFooterImage"
              dropzoneOptions={{
                maxFiles: 1,
              }}
              fullWidth
            />
          </TwoColsLayout>
        </Box>
        <Box mt={4}>
          <FormikInputField
            label={"URL subdomain name"}
            placeholder={"URL subdomain name"}
            name="url"
            fullWidth
            required
          />
        </Box>
        <Box mt={4}>
          <TwoColsLayout>
            <FormikInputField
              label={"Facebook Profile URL"}
              placeholder={"Facebook Profile URL"}
              name="facebookProfile"
              fullWidth
              required
            />
            <FormikInputField
              label={"Instagram Profile URL"}
              placeholder={"Instagram Profile URL"}
              name="instagramProfile"
              fullWidth
              required
            />
            <FormikInputField
              label={"X Profile URL"}
              placeholder={"X Profile URL"}
              name="xProfile"
              fullWidth
              required
            />
            <FormikInputField
              label={"LinkedIn Profile URL"}
              placeholder={"LinkedIn Profile URL"}
              name="linkedInProfile"
              fullWidth
              required
            />
          </TwoColsLayout>
        </Box>
        <Box mt={4}>
          <TwoColsLayout>
            <FormikInputField
              label={"Reply To Email"}
              placeholder={"Reply To Email"}
              name="replyToEmail"
              type={"email"}
              fullWidth
              required
            />
            <FormikInputField
              label={"Reply To Email Name"}
              placeholder={"Reply To Email Name"}
              name="replyToEmailName"
              type={"email"}
              fullWidth
              required
            />
            <FormikInputField
              label={"Email Copyright Year"}
              placeholder={"Email Copyright Year"}
              name="emailCopyrightYear"
              fullWidth
              required
            />
            <FormikInputField
              label={"Email Account Name"}
              placeholder={"Email Account Name"}
              name="emailAccountName"
              fullWidth
              required
            />
            <FormikInputField
              label={"Phone Number"}
              placeholder={"Phone Number"}
              name="phoneNumber"
              fullWidth
              required
            />
            <FormikInputField
              label={"Phone Number Text"}
              placeholder={"Phone Number Text"}
              name="phoneNumberText"
              fullWidth
              required
            />
          </TwoColsLayout>
        </Box>
        <Box mt={4}>
          <TwoColsLayout>
            <FormikInputField
              label={"Terms And Conditions"}
              placeholder={"Terms And Conditions"}
              name="termsAndConditions"
              fullWidth
              required
            />
            <FormikInputField
              label={"Privacy"}
              placeholder={"Privacy"}
              name="privacy"
              fullWidth
              required
            />
            <FormikInputField
              label={"Footer Text"}
              placeholder={"Footer Text"}
              name="footerText"
              fullWidth
              required
            />
            <FormikInputField
              label={"Website Text"}
              placeholder={"Website Text"}
              name="websiteText"
              fullWidth
              required
            />
          </TwoColsLayout>
        </Box>
        <Box mt={4}>
          <TwoColsLayout hideDivider>
            <FormikToggleField
              label="Allow Rides Registration Report"
              name="allowRidesRegistrationReport"
            />
          </TwoColsLayout>
        </Box>
      </ContentSectionLayout>
      {isInEdit && (
        <ContentSectionLayout
          title="White label read only"
          subTitle="Some text to help user understand what this block is responsible for."
          hideDivider
        >
          <Stack spacing={2}>
            <FormikInputField
              label={"Static Site Url"}
              placeholder={"Static Site Url"}
              name="staticSiteUrl"
              fullWidth
              disabled
            />
            <FormikInputField
              label={"Login Page Url"}
              placeholder={"Login Page Url"}
              name="loginPageUrl"
              fullWidth
              disabled
            />
            <FormikInputField
              label={"Azure Ad Policy Name"}
              placeholder={"Azure Ad Policy Name"}
              name="azureAdPolicyName"
              fullWidth
              disabled
            />
          </Stack>
          <Box mt={4}>
            <TwoColsLayout hideDivider>
              <FormikToggleField
                label="Is Deploy Pipeline Configured"
                name="isDeployPipelineConfigured"
                disabled
              />
              <FormikToggleField
                label="Is Application Created"
                name="isApplicationCreated"
                disabled
              />
              <FormikToggleField
                label="Is Azure B2C Configured"
                name="isAzureB2CConfigured"
                disabled
              />
              <FormikToggleField
                label="Is Login Page Configured"
                name="isLoginPageConfigured"
                disabled
              />
            </TwoColsLayout>
          </Box>
        </ContentSectionLayout>
      )}
    </Box>
  );
};

export default InnerForm;
