import { FC, Fragment, useState, useEffect } from "react";
import { DeviceDetailsSubtitle } from "../DeviceDetailsHeader/DeviceDetailsHeader.styled";
import { Typography } from "@mui/material";
import LocationTimeline from "components/ui/timeline/LocationTimeline/LocationTimeline";
import {
  DateRangeContainer,
  TimelineContainer,
} from "./DeviceLocationHistorySection.styled";
import { DateRangePicker } from "@vilocnv/allsetra-core";
import { useActiveDevice, useAppDispatch, useAppSelector } from "hooks";
import {
  getDeviceLocationByDateThunk,
  resetdeviceHistoryData,
} from "app/features";
import { selectDevicesState } from "app/data/selectors";
import { CircularProgress } from "@mui/material";
import { convertToNormalTime } from "app/data/helpers";

const DeviceLocationHistorySection: FC = () => {
  //Global state
  const { specificDevice } = useActiveDevice({});
  const dispatch = useAppDispatch();

  const { deviceHistoryByDate, deviceHistoryLoading } =
    useAppSelector(selectDevicesState);

  useEffect(() => {
    handleSubmit();
    return () => {
      dispatch(resetdeviceHistoryData());
    };
  }, []);

  //Local state
  const [value, setValue] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const handleDateChange = (value: any) => {
    setValue(value);
  };

  const handleSubmit = () => {
    const startDate = new Date(value.startDate);
    const endDate = new Date(value.endDate);

    const sameDate = startDate.toDateString() === endDate.toDateString();

    if (sameDate) {
      startDate.setHours(0, 0, 0);
      endDate.setHours(23, 59, 59);
      endDate.setDate(endDate.getDate() - 1);
    }

    const formattedStartDate = convertToNormalTime(startDate);
    const formattedEndDate = convertToNormalTime(endDate, true);
    dispatch(
      getDeviceLocationByDateThunk({
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        deviceId: specificDevice?.uniqueId,
      })
    );
  };

  return (
    <Fragment>
      <DeviceDetailsSubtitle>
        {specificDevice?.deviceType.name}
      </DeviceDetailsSubtitle>
      <Typography variant={"h2"}>{specificDevice?.name}</Typography>
      <DateRangeContainer>
        <DateRangePicker
          onChange={handleDateChange}
          value={value}
          name="datepicker"
          onSubmit={handleSubmit}
        />
      </DateRangeContainer>
      <TimelineContainer>
        {deviceHistoryLoading ? (
          <CircularProgress size={35} />
        ) : (
          <LocationTimeline timeLineItems={deviceHistoryByDate} />
        )}
      </TimelineContainer>
    </Fragment>
  );
};

export default DeviceLocationHistorySection;
