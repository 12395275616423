import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast, types } from "@vilocnv/allsetra-core";
import { Devices } from "app/data/services";

export const getDeviceLocationHistoryThunk = createAsyncThunk(
  "devices/getDeviceLocationHistoryThunk",
  async ({ deviceId, locationSearch }: any) => {
    try {
      const response = await Devices.getDeviceLocationHistory(
        deviceId,
        locationSearch
      );

      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getAllDevicesThunk = createAsyncThunk(
  "devices/getAllDevicesThunk",
  async () => {
    try {
      const response = await Devices.getAllDevices();

      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getSpecificDeviceThunk = createAsyncThunk(
  "devices/getSpecificDeviceThunk",
  async (deviceId: string) => {
    try {
      const response = await Devices.getSpecificDeviceById(deviceId);

      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getDevicesByQueryThunk = createAsyncThunk(
  "devices/getDevicesByQueryThunk",
  async (params: types.IRecordsAggregationBody) => {
    try {
      const response = await Devices.getDevicesByQuery(params);

      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const deactivateDeviceThunk = createAsyncThunk(
  "devices/deactivateDeviceThunk",
  async (deviceId: string, { dispatch }) => {
    try {
      const response = await Devices.deactivateDevice(deviceId);

      if (response.status === 202) {
        toast.success("Device has been deactivated");
        dispatch(getAllDevicesThunk());
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const reactivateDeviceThunk = createAsyncThunk(
  "devices/reactivateDeviceThunk",
  async (deviceId: string, { dispatch }) => {
    try {
      const response = await Devices.reactivateDevice(deviceId);

      if (response.status === 202) {
        toast.success("Device has been reactivated");
        dispatch(getAllDevicesThunk());
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const postUpdateDeviceThunk = createAsyncThunk(
  "devices/postUpdateDeviceThunk",
  async (data: any, { dispatch }) => {
    try {
      const response = await Devices.postUpdateDevice(data);

      if (response.status === 202) {
        toast.success("Device settings have been updated");
        dispatch(getSpecificDeviceThunk(data.uniqueId));
      }

      return response;
    } catch (e: any) {
      console.error(e);
      toast.error(e?.response?.data?.title || e.message);
      throw new Error(e);
    }
  }
);

export const getAllSubscriptionsByDeviceIdThunk = createAsyncThunk(
  "devices/getAllSubscriptionsByDeviceIdThunk",
  async (deviceId: string) => {
    try {
      const response = await Devices.getAllSubscriptionsByDeviceId(deviceId);
      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const enableDeviceImmobilizerThunk = createAsyncThunk(
  "devices/enableDeviceImmobilizerThunk",
  async (deviceId: string) => {
    try {
      const response = await Devices.enableDeviceImmobilizer(deviceId);

      if (response.status === 202) {
        toast.success("Device immobilizer has been enabled");
      }

      return response;
    } catch (e: any) {
      if (e.response?.status === 400) {
        toast.error(e.response.data.detail);
      } else {
        toast.error(e.message);
      }
      console.error(e);
      throw new Error(e);
    }
  }
);

export const disableDeviceImmobilizerThunk = createAsyncThunk(
  "devices/disableDeviceImmobilizerThunk",
  async (deviceId: string) => {
    try {
      const response = await Devices.disableDeviceImmobilizer(deviceId);

      if (response.status === 202) {
        toast.success("Device immobilizer has been disabled");
      }

      return response;
    } catch (e: any) {
      if (e.response?.status === 400) {
        toast.error(e.response.data.detail);
      } else {
        toast.error(e.message);
      }
      console.error(e);
      throw new Error(e);
    }
  }
);

export const deviceRawCommandThunk = createAsyncThunk(
  "devices/deviceRawCommandThunk",
  async ({ deviceId, data }: any) => {
    try {
      const response = await Devices.deviceRawCommand(deviceId, data);

      if (response.status === 202) {
        toast.success(
          "Device raw command request is being processed by the backend."
        );
      }

      return response;
    } catch (e: any) {
      toast.error(e.message);
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getDeviceLocationByDateThunk = createAsyncThunk(
  "devices/getDeviceLocationByDateThunk",
  async ({ deviceId, startDate, endDate }: any) => {
    try {
      const response = await Devices.getDeviceLocationsByDate(
        deviceId,
        startDate,
        endDate
      );

      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getMetaDataByDeviceIdThunk = createAsyncThunk(
  "objects/getMetaDataByDeviceIdThunk",
  async ({
    deviceId,
    startDate = "",
    endDate = "",
    eventType = "PARSED",
  }: any) => {
    try {
      const response: any =
        eventType === "PARSED"
          ? await Devices.getParsedMetaDataById(deviceId, startDate, endDate)
          : await Devices.getMetaDataById(deviceId, startDate, endDate);
      if (response.status === 200) {
        return response.data;
      }
      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

//
// Device Commands Thunks
//
export const disableDeviceAlarmThunk = createAsyncThunk(
  "devices/disableDeviceAlarmThunk",
  async (deviceId: string) => {
    try {
      const response = await Devices.disableDeviceAlarm(deviceId);

      if (response.status === 202) {
        toast.success("Device alarm has been disabled");
      }

      return response;
    } catch (e: any) {
      if (e.response?.status === 400) {
        toast.error(e.response.data.detail);
      } else {
        toast.error(e.message);
      }
      console.error(e);
      throw new Error(e);
    }
  }
);

export const deviceEmergencyToggleThunk = createAsyncThunk(
  "devices/deviceEmergencyToggleThunk",
  async ({ deviceId, data }: { deviceId: string; data: any }) => {
    try {
      const response = await Devices.deviceEmergencyToggle(deviceId, data);

      if (response.status === 202) {
        toast.success("Device emergency mode request is accepted");
      }

      return response;
    } catch (e: any) {
      if (e.response?.status === 400) {
        toast.error(e.response.data.detail);
      } else {
        toast.error(e.message);
      }
      console.error(e);
      throw new Error(e);
    }
  }
);

export const deviceResetGPSThunk = createAsyncThunk(
  "devices/deviceResetGPSThunk",
  async (deviceId: string) => {
    try {
      const response = await Devices.deviceResetGPS(deviceId);

      if (response.status === 202) {
        toast.success("Device GPS has been reset");
      }

      return response;
    } catch (e: any) {
      if (e.response?.status === 400) {
        toast.error(e.response.data.detail);
      } else {
        toast.error(e.message);
      }
      console.error(e);
      throw new Error(e);
    }
  }
);

export const deviceUploadPLFileThunk = createAsyncThunk(
  "devices/deviceUploadPLFileThunk",
  async ({ deviceId, file }: { deviceId: string; file: File }) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await Devices.deviceUploadPLFile(deviceId, formData);

      if (response.status === 202) {
        toast.success("Device PL file uploaded successfully");
      }

      return response;
    } catch (e: any) {
      if (e.response?.status === 400) {
        toast.error(e.response.data.detail);
      } else {
        toast.error(e.message);
      }
      console.error(e);
      throw new Error(e);
    }
  }
);

export const deviceConnectManufacturerThunk = createAsyncThunk(
  "devices/deviceConnectManufacturerThunk",
  async (deviceId: string) => {
    try {
      const response = await Devices.deviceConnectManufacturer(deviceId);

      if (response.status === 202) {
        toast.success("Device manufacturer has been connected");
      }

      return response;
    } catch (e: any) {
      if (e.response?.status === 400) {
        toast.error(e.response.data.detail);
      } else {
        toast.error(e.message);
      }
      console.error(e);
      throw new Error(e);
    }
  }
);

export const deviceAutoCANToggleThunk = createAsyncThunk(
  "devices/deviceAutoCANToggleThunk",
  async (deviceId: string) => {
    try {
      const response = await Devices.deviceAutoCANToggle(deviceId);

      return response;
    } catch (e: any) {
      if (e.response?.status === 400) {
        toast.error(e.response.data.detail);
      } else {
        toast.error(e.message);
      }
      console.error(e);
      throw new Error(e);
    }
  }
);

export const devicePollCommandThunk = createAsyncThunk(
  "devices/devicePollCommandThunk",
  async (deviceId: string) => {
    try {
      const response = await Devices.devicePollCommand(deviceId);

      if (response.status === 202) {
        toast.success("Device poll command successful");
      }

      return response;
    } catch (e: any) {
      if (e.response?.status === 400) {
        toast.error(e.response.data.detail);
      } else {
        toast.error(e.message);
      }
      console.error(e);
      throw new Error(e);
    }
  }
);
