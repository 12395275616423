import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import {
  UnauthenticatedTemplate,
  AuthenticatedTemplate,
  useMsal,
  useIsAuthenticated,
} from "@azure/msal-react";
import { DashboardLayout, PageLoader } from "@vilocnv/allsetra-core";
import * as Sentry from "@sentry/react";

// DATA
import { useTranslation } from "react-i18next";
import {
  useAppDispatch,
  useAppSelector,
  useSetLangOnSettingsChange,
} from "hooks";
import {
  getAccountsBasicInfoThunk,
  setDrawerCollapseState,
} from "app/features";
import { selectIsDrawerCollapsed } from "app/data/selectors";
import { getDrawerMenuItems, getDrawerSubMenuLists } from "app/data/constants";
import { SignalRService } from "app/data/services";
import { signalRConnectionEvent } from "app/data/services/SignalRService";

export interface ProtectedRouteProps {
  redirectTo: string;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ redirectTo }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname, search } = useLocation();
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const isDrawerCollapsed = useAppSelector(selectIsDrawerCollapsed);

  // Local
  const [isOnline, setIsOnline] = useState<boolean>(false); // Saves the state for network
  const [renderCycle, setRenderCycle] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useSetLangOnSettingsChange();

  useEffect(() => {
    setIsOnline(window.navigator.onLine);

    const setToOnline = () => setIsOnline(true);

    const setToOffline = () => setIsOnline(false);

    window.addEventListener("online", setToOnline);
    window.addEventListener("offline", setToOffline);

    dispatch(getAccountsBasicInfoThunk());

    return () => {
      window.removeEventListener("online", setToOnline);
      window.removeEventListener("offline", setToOffline);
    };
  }, []);

  const { t } = useTranslation();

  const toggleDrawerCollapseState = () => {
    dispatch(setDrawerCollapseState(!isDrawerCollapsed));
  };

  const handleLogout = useCallback(async () => {
    try {
      await instance.logoutRedirect({ postLogoutRedirectUri: "/" });
      Sentry.setUser(null);
      window.localStorage.clear();
    } catch (error) {
      console.error("Error during logout process:", error);
    }
  }, [instance]);

  const { drawerMenuItems, drawerSubMenuLists } = {
    drawerMenuItems: getDrawerMenuItems(t),
    drawerSubMenuLists: getDrawerSubMenuLists(),
  };

  const fullPath = `${pathname}${search}`;

  window.onbeforeunload = () => {
    sessionStorage.setItem("oldPathname", fullPath);
  };

  useEffect(() => {
    if (pathname?.split("/dashboard")[1]) {
      sessionStorage.setItem("pathname", fullPath);
    }
  }, [pathname]);

  useEffect(() => {
    const oldPath = sessionStorage.getItem("oldPathname");

    const _pathname = sessionStorage.getItem("pathname");

    if (_pathname === oldPath && !isAuthenticated) {
      navigate(_pathname as string);
    } else {
      return;
    }
  }, [isAuthenticated]);

  useEffect(() => {
    signalRConnectionEvent.subscribe((value) => {
      if (value === "Connected") {
        setRenderCycle((cycle) => cycle + 1);
      }

      setIsLoading(false);
    });
  }, []);

  return (
    <Fragment>
      <UnauthenticatedTemplate>
        <Navigate to={redirectTo} state={{ from: pathname }} />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate key={renderCycle}>
        {isLoading ? (
          <PageLoader sx={{ width: "100dvw" }} />
        ) : (
          <DashboardLayout
            // @ts-ignore
            size={"large"}
            isDrawerCollapsed={isDrawerCollapsed}
            toggleDrawerCollapseState={toggleDrawerCollapseState}
            appLogoType={"admin"}
            menuList={drawerMenuItems}
            subLists={drawerSubMenuLists}
            activeLinkTextColor={theme.palette.primary.light}
            activeLinkBgColor={theme.palette.primary.dark}
            showSignalRBadge={true}
            isSignalRConnected={
              SignalRService.hubConnection?.state === "Connected" && isOnline
            }
            onSupportClick={() => {}}
            onLogoutClick={handleLogout}
          />
        )}
      </AuthenticatedTemplate>
    </Fragment>
  );
};

export default ProtectedRoute;
