import { FC, useMemo, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { useNavigate } from "react-router-dom";
import { Topbar, toast, types } from "@vilocnv/allsetra-core";
import { isEmpty } from "lodash";

//Children
import PersonalInfoForm from "./children/PersonalInfoForm";
import InterfaceForm from "./children/InterfaceForm";

//Data
import {
  userSettingsInitialValues,
  userSettingsValidationSchema,
} from "app/data/helpers/settingsHelpers";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectLanguageState, selectSettingsState } from "app/data/selectors";
import { ISettings } from "app/data/types";
import { getSpecificSettingThunk, updateSettingsThunk } from "app/features";
import { SignalRService } from "app/data/services";
import { changeLanguage } from "app/data/helpers";

const UpdateSettingsForm: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  //Global States
  const { specificSetting } = useAppSelector(selectSettingsState);
  const { languagesLoading, languages } = useAppSelector(selectLanguageState);

  // Local State
  const [processing, setProcessing] = useState<boolean>(false);

  const formValues = useMemo(
    () =>
      !isEmpty(specificSetting) ? specificSetting : userSettingsInitialValues,
    [specificSetting]
  );

  const handleUserSettings = async (
    values: ISettings,
    formikHelpers: FormikHelpers<ISettings>
  ) => {
    setProcessing(true);
    formikHelpers.setSubmitting(true);

    const { type } = await dispatch(
      updateSettingsThunk({ ...values, languageId: values.language })
    );

    if (type === "settings/updateSettingsThunk/fulfilled") {
      SignalRService.hubConnection?.on("EventRaised", (event: any) => {
        if (event.eventName === types.BackendEventsEnum.UserUpdatedEvent) {
          setProcessing(false);
          formikHelpers.setSubmitting(false);

          toast.success("Settings have been updated.");

          changeLanguage(event.preferredLanguage || "en");
          dispatch(getSpecificSettingThunk());
        }
      });
    } else {
      setProcessing(false);
      formikHelpers.setSubmitting(false);
    }
  };

  return (
    <Formik
      //@ts-ignore
      initialValues={formValues}
      onSubmit={handleUserSettings}
      validationSchema={userSettingsValidationSchema}
      enableReinitialize
      validateOnMount
    >
      {({ handleSubmit, isSubmitting, dirty, isValid }) => (
        <Form>
          <Topbar
            theme={theme}
            title="User Settings"
            primaryButton={{
              id: "save",
              variant: "contained",
              text: "Save Changes",
              onClick: handleSubmit,
              loading: isSubmitting || processing,
              disabled: !dirty ? isValid : !isValid,
            }}
            secondaryButton={{
              id: "cancel",
              variant: "text",
              text: "Cancel",
              onClick: () => {
                navigate(-1);
              },
            }}
          />
          <Box px={"32px"}>
            <PersonalInfoForm />
            <InterfaceForm
              languages={languages}
              languagesLoading={languagesLoading}
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default UpdateSettingsForm;
