import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Badge,
  Button,
  DeleteConfirmationModal,
  Dropdown,
  helpers,
  types,
} from "@vilocnv/allsetra-core";
import { Typography, useTheme } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { PointerHistoryIcon } from "assets/icons";
import {
  ButtonsWrapper,
  HeaderContainer,
} from "components/sections/objects/ObjectDetailsHeader/ObjectDetailsHeader.styled";
import {
  DeviceDetailsSubtitle,
  HeadingMainTitleWithBadge,
  HeadingTitleContainer,
} from "./DeviceDetailsHeader.styled";
import UploadFileModal from "./UploadFileModal";

// Data
import { useAppDispatch } from "hooks";
import {
  deviceAutoCANToggleThunk,
  deviceConnectManufacturerThunk,
  deviceEmergencyToggleThunk,
  devicePollCommandThunk,
  deviceResetGPSThunk,
  deviceUploadPLFileThunk,
  disableDeviceAlarmThunk,
  disableDeviceImmobilizerThunk,
  enableDeviceImmobilizerThunk,
} from "app/features";
import { DeviceCommandTypes } from "app/data/types";

interface Props {
  specificDevice: types.IDevice | null;
}

const DeviceDetailsHeader: FC<Props> = ({ specificDevice }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [selectedCommand, setSelectedCommand] =
    useState<DeviceCommandTypes | null>(null);
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [uploadFileModal, setUploadFileModal] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const deviceId = specificDevice?.uniqueId || "";

  const handleConfigureClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCommnadClick = (command: DeviceCommandTypes) => {
    setSelectedCommand(command);
    setConfirmationModal(true);
  };

  const handleCommandAPICall = async () => {
    setLoading(true);

    switch (selectedCommand) {
      case "Enable Immobilizer":
        await dispatch(enableDeviceImmobilizerThunk(deviceId));
        break;

      case "Disable Immobilizer":
        await dispatch(disableDeviceImmobilizerThunk(deviceId));
        break;

      case "Disable Alarm":
        await dispatch(disableDeviceAlarmThunk(deviceId));
        break;

      case "Enable/Disable Emergency Mode":
        const data = {
          numberOfMessages: 4,
          intervalInSeconds: 30,
        };
        await dispatch(deviceEmergencyToggleThunk({ deviceId, data }));
        break;

      case "Reset GPS":
        await dispatch(deviceResetGPSThunk(deviceId));
        break;

      case "Connect Manufacturer":
        await dispatch(deviceConnectManufacturerThunk(deviceId));
        break;

      case "Enable/Disable AutoCAN":
        await dispatch(deviceAutoCANToggleThunk(deviceId));
        break;

      case "Poll Command":
        await dispatch(devicePollCommandThunk(deviceId));
        break;

      default:
        console.error("Invalid device command selected");
    }

    setLoading(false);
    setConfirmationModal(false);
  };

  const handleUploadPLFile = async () => {
    setUploadFileModal(true);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const submitUploadFileModal = async () => {
    if (selectedFile) {
      setLoading(true);
      await dispatch(deviceUploadPLFileThunk({ deviceId, file: selectedFile }));
      setLoading(false);
      setSelectedFile(null);
      setUploadFileModal(false);
    }
  };

  return (
    <HeaderContainer>
      <HeadingTitleContainer>
        <DeviceDetailsSubtitle>
          {specificDevice?.deviceType.name || "N/A"}
        </DeviceDetailsSubtitle>
        <HeadingMainTitleWithBadge
          flexDirection={{ md: "row", xs: "column" }}
          gap={{ xs: "12px", md: "0px" }}
        >
          <Typography variant={"h2"}>
            {specificDevice?.serialNumber || "N/A"}
          </Typography>
          <Badge
            colorScheme={helpers.getDeviceBadgeColorOnStatus(
              specificDevice?.status || "N/A"
            )}
          >
            {specificDevice?.status || "N/A"}
          </Badge>
        </HeadingMainTitleWithBadge>
      </HeadingTitleContainer>
      <ButtonsWrapper>
        <Button
          variant={"outlined"}
          startIcon={<PointerHistoryIcon />}
          size={"small"}
          onClick={() =>
            navigate(`/dashboard/devices/${deviceId}/location-history`)
          }
        >
          Location history
        </Button>
        <Button
          loading={loading}
          onClick={handleConfigureClick}
          variant={"outlined"}
          endIcon={<ExpandMore />}
          size={"small"}
        >
          Configure
        </Button>
        <Dropdown
          increasedWidth={true}
          items={[
            {
              name: "Enable Immobilizer",
              onClick: () => handleCommnadClick("Enable Immobilizer"),
            },
            {
              name: "Disable Immobilizer",
              onClick: () => handleCommnadClick("Disable Immobilizer"),
            },
            {
              name: "Disable Alarm",
              onClick: () => handleCommnadClick("Disable Alarm"),
            },
            {
              name: "Enable/Disable Emergency Mode",
              onClick: () =>
                handleCommnadClick("Enable/Disable Emergency Mode"),
            },
            {
              name: "Reset GPS",
              onClick: () => handleCommnadClick("Reset GPS"),
            },
            {
              name: "Upload PL File",
              onClick: handleUploadPLFile,
            },
            {
              name: "Connect to manufacturer portal",
              onClick: () => handleCommnadClick("Connect Manufacturer"),
            },
            {
              name: "Enable/Disable AutoCAN",
              onClick: () => handleCommnadClick("Enable/Disable AutoCAN"),
            },
            {
              name: "Poll Command",
              onClick: () => handleCommnadClick("Poll Command"),
            },
          ]}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
      </ButtonsWrapper>
      <UploadFileModal
        open={uploadFileModal}
        onClose={() => setUploadFileModal(false)}
        handleSubmit={submitUploadFileModal}
        handleFileChange={handleFileChange}
        isSubmitting={loading}
      />
      <DeleteConfirmationModal
        open={confirmationModal}
        onClose={() => {
          setSelectedCommand(null);
          setConfirmationModal(false);
        }}
        title={`You are about to ${selectedCommand}`}
        subTitle={`Do you really want to ${selectedCommand}? This process cannot be undone.`}
        primaryBtnProps={{
          loading: loading,
          text: "Continue",
          onClick: handleCommandAPICall,
        }}
        theme={theme}
      />
    </HeaderContainer>
  );
};

export default DeviceDetailsHeader;
