import { types } from "@vilocnv/allsetra-core";
import axiosInstance from "app/axiosInstance";

class Devices {
  static getAllDevices = async () => {
    return await axiosInstance.get("/devices");
  };

  static getDevicesByQuery = async (data: types.IRecordsAggregationBody) => {
    return await axiosInstance.post("/devices/search", data);
  };

  static getMetaDataById = async (
    deviceId: string,
    startDate?: string,
    endDate?: string
  ) => {
    return await axiosInstance.get(
      `/devices/${deviceId}/events/metadata?startDate=${startDate}&endDate=${endDate}`
    );
  };

  static getParsedMetaDataById = async (
    deviceId: string,
    startDate?: string,
    endDate?: string
  ) => {
    return await axiosInstance.get(
      `/devices/${deviceId}/events/update-history?startDate=${startDate}&endDate=${endDate}`
    );
  };

  static getSpecificDeviceById = async (deviceId: string) => {
    return await axiosInstance.get(`/devices/${deviceId}`);
  };

  static postUpdateDevice = async (data: any) => {
    return await axiosInstance.patch(`/devices/${data.uniqueId}`, data);
  };

  static getDeviceLocationHistory = async (
    deviceId: string,
    locationSearch: string
  ) => {
    return await axiosInstance.get(
      `/devices/${deviceId}/locations${locationSearch}`
    );
  };

  static getDeviceLocationsByDate = async (
    deviceId: string,
    startDate: string,
    endDate: string
  ) => {
    return await axiosInstance.get(
      `/devices/${deviceId}/locations?startDate=${startDate}&endDate=${endDate}`
    );
  };

  static deactivateDevice = async (deviceId: string) => {
    return await axiosInstance.delete(`/devices/${deviceId}`);
  };

  static reactivateDevice = async (deviceId: string) => {
    return await axiosInstance.patch(`/devices/${deviceId}/activate`);
  };

  static getAllSubscriptionsByDeviceId = async (deviceId: string) => {
    return await axiosInstance.get(`/devices/${deviceId}/subscriptions`);
  };

  static enableDeviceImmobilizer = async (deviceId: string) => {
    return await axiosInstance.post(`/devices/${deviceId}/immobilizer/enable`);
  };

  static disableDeviceImmobilizer = async (deviceId: string) => {
    return await axiosInstance.post(`/devices/${deviceId}/immobilizer/disable`);
  };

  //
  // Device Commands Endpoints
  //
  static disableDeviceAlarm = async (deviceId: string) => {
    return await axiosInstance.post(
      `/devices/${deviceId}/commands/alarms/disable`
    );
  };

  static deviceEmergencyToggle = async (deviceId: string, data: any) => {
    return await axiosInstance.post(
      `/devices/${deviceId}/commands/emergency-mode`,
      data
    );
  };

  static deviceResetGPS = async (deviceId: string) => {
    return await axiosInstance.post(`/devices/${deviceId}/commands/gps/reset`);
  };

  static deviceUploadPLFile = async (deviceId: string, fileData: FormData) => {
    return await axiosInstance.post(
      `/devices/${deviceId}/commands/ProgramPLFile`,
      fileData
    );
  };

  static deviceConnectManufacturer = async (deviceId: string) => {
    return await axiosInstance.post(
      `/devices/${deviceId}/commands/manufacturer/connect`
    );
  };

  static deviceAutoCANToggle = async (deviceId: string) => {
    return await axiosInstance.post(
      `/devices/${deviceId}/commands/manufacturer/get-configuration`
    );
  };

  static deviceRawCommand = async (deviceId: string, data: any) => {
    return await axiosInstance.post(`/devices/${deviceId}/commands/raw`, data);
  };

  static devicePollCommand = async (deviceId: string) => {
    return await axiosInstance.post(`/devices/${deviceId}/commands/poll`);
  };
}

export default Devices;
