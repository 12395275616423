import { createSlice } from "@reduxjs/toolkit";
import { types } from "@vilocnv/allsetra-core";
import {
  getAlarmConfigByObjectId,
  getAllObjectsThunk,
  getAllSubscriptionsByObjectIdThunk,
  getObjectsByQueryThunk,
  getObjectsLocationsThunk,
  getSpecificObjectByIdThunk,
} from "./objectsActions";

export interface IObjectState {
  loading: boolean;
  totalRecords: number | null;
  activeObject: types.IObject | null;
  activeObjectLoading: boolean;
  allObjects: Array<any>;
  objects: Array<any>;
  objectSubscriptions: Array<any>;
  objectSubscriptionsLoading: boolean;
  objectsMeta: Array<any>;
  alarmsConfigLoading: boolean;
  alarmsConfig: {
    effectiveConfiguration: Array<any>;
    groupHasCustomAlarmConfiguration: boolean | null;
    objectHasCustomAlarmConfiguration: boolean | null;
  };
}

const initialState: IObjectState = {
  loading: true,
  totalRecords: null,
  activeObject: null,
  activeObjectLoading: false,
  allObjects: [],
  objects: [],
  objectSubscriptions: [],
  objectSubscriptionsLoading: false,
  objectsMeta: [],
  alarmsConfigLoading: false,
  alarmsConfig: {
    effectiveConfiguration: [],
    groupHasCustomAlarmConfiguration: null,
    objectHasCustomAlarmConfiguration: null,
  },
};

const objectsSlice = createSlice({
  name: "objects",
  initialState,
  reducers: {
    setActiveObject: (state, action) => {
      state.activeObject = action.payload;
    },
    clearObjectData: () => initialState,
    resetAllObjects: (state) => {
      state.allObjects = [];
    },
    setObjectMeta: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.objectsMeta = [
          ...(state.objectsMeta ? state.objectsMeta : []),
          ...action.payload,
        ];
      } else if (action.payload) {
        state.objectsMeta = [
          ...(state.objectsMeta ? state.objectsMeta : []),
          action.payload,
        ];
      } else {
        console.error("Invalid payload. Expected an array or an object.");
      }
    },
  },
  extraReducers: (builder) => {
    // Get All Objects Action Cases
    builder.addCase(getAllObjectsThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getAllObjectsThunk.fulfilled, (state, action) => {
      state.allObjects = action.payload || [];
      state.loading = false;
    });

    builder.addCase(getAllObjectsThunk.rejected, (state) => {
      state.loading = false;
    });

    // Get Objects By Query Thunk
    builder.addCase(getObjectsByQueryThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getObjectsByQueryThunk.fulfilled, (state, action) => {
      state.objects = action.payload?.results || [];
      state.totalRecords = action.payload?.rowCount || 0;
      state.loading = false;
    });

    builder.addCase(getObjectsByQueryThunk.rejected, (state) => {
      state.loading = false;
    });

    // Get Specifc Object By ID Thunk
    builder.addCase(getSpecificObjectByIdThunk.pending, (state) => {
      state.activeObjectLoading = true;
      state.activeObject = null;
    });

    builder.addCase(getSpecificObjectByIdThunk.fulfilled, (state, action) => {
      state.activeObject = action.payload;
      state.activeObjectLoading = false;
    });

    builder.addCase(getSpecificObjectByIdThunk.rejected, (state) => {
      state.activeObjectLoading = false;
      state.activeObject = null;
    });

    // Get Subscriptions of Object By ID Thunk
    builder.addCase(getAllSubscriptionsByObjectIdThunk.pending, (state) => {
      state.objectSubscriptionsLoading = true;
    });

    builder.addCase(
      getAllSubscriptionsByObjectIdThunk.fulfilled,
      (state, action) => {
        state.objectSubscriptions = action.payload;
        state.objectSubscriptionsLoading = false;
      }
    );

    builder.addCase(getAllSubscriptionsByObjectIdThunk.rejected, (state) => {
      state.objectSubscriptionsLoading = false;
    });

    // Get Specifc Object By ID Thunk
    builder.addCase(getObjectsLocationsThunk.pending, (state) => {
      state.loading = true;
      state.allObjects = [];
    });

    builder.addCase(getObjectsLocationsThunk.fulfilled, (state, action) => {
      state.allObjects = action.payload;
      state.loading = false;
    });

    builder.addCase(getObjectsLocationsThunk.rejected, (state) => {
      state.loading = false;
      state.allObjects = [];
    });

    // Get Alarms Config bu object ID Thunk
    builder.addCase(getAlarmConfigByObjectId.pending, (state) => {
      state.alarmsConfigLoading = true;
    });

    builder.addCase(getAlarmConfigByObjectId.fulfilled, (state, action) => {
      state.alarmsConfig = action.payload;
      state.alarmsConfigLoading = false;
    });

    builder.addCase(getAlarmConfigByObjectId.rejected, (state) => {
      state.alarmsConfigLoading = false;
    });
  },
});

export * from "./objectsActions";
export const {
  setActiveObject,
  clearObjectData,
  resetAllObjects,
  setObjectMeta,
} = objectsSlice.actions;

export default objectsSlice.reducer;
