import { FC, useCallback, useEffect, useState } from "react";
import { Box, Typography, Grid, Select, MenuItem } from "@mui/material";
import {
  Button,
  DateRangePicker,
  Modal,
  Table,
  toast,
} from "@vilocnv/allsetra-core";
import { useAppDispatch, useAppSelector } from "hooks";
import { getMetaDataByDeviceIdThunk } from "app/features";
import { selectDeviceSubscriptionsById } from "app/data/selectors";
import theme from "app/theme";
import {
  convertToNormalTime,
  getDeviceEventsTableColumns,
  transformDeviceEventsDataForTable,
} from "app/data/helpers";

interface Props {
  specificDevice: any;
  deviceId: string;
}

const DeviceDetailsTables: FC<Props> = ({ specificDevice, deviceId }) => {
  const dispatch = useAppDispatch();

  const { deviceEventsMetaData, deviceEventsMetaDataLoading } = useAppSelector(
    selectDeviceSubscriptionsById
  );

  const [eventType, setEventType] = useState<"PARSED" | "RAW">("PARSED");
  const [isModalOpen, setModal] = useState<boolean>(false);
  const [rowJSON, setJSON] = useState<string>("");
  const [value, setValue] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const getDeviceEventsData = useCallback(() => {
    const startDate = new Date(value.startDate);
    const endDate = new Date(value.endDate);

    const sameDate = startDate.toDateString() === endDate.toDateString();

    if (sameDate) {
      startDate.setHours(0, 0, 0);
      endDate.setHours(23, 59, 59);
      endDate.setDate(endDate.getDate() - 1);
    }

    const formattedStartDate = convertToNormalTime(startDate);
    const formattedEndDate = convertToNormalTime(endDate, true);

    dispatch(
      getMetaDataByDeviceIdThunk({
        deviceId,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        eventType,
      })
    );
  }, [value, deviceId, eventType]);

  useEffect(() => {
    if (specificDevice) {
      getDeviceEventsData();
    }
  }, [eventType]);

  useEffect(() => {
    if (rowJSON) setModal(true);
  }, [rowJSON]);

  // const columns = useMemo(
  //   () => getDeviceEventsTableColumns(deviceEventsMetaData, eventType),
  //   [deviceEventsMetaData, eventType]
  // );

  const handleDateChange = (value: any) => {
    setValue(value);
  };

  const handleViewInfo = (item: any) => {
    try {
      setJSON(JSON.stringify(item));
      setModal(true);
    } catch (error) {
      toast.error("Failed to convert null values into JSON");
    }
  };

  const onCloseModal = () => {
    setModal(false);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(rowJSON);
    toast.success("JSON copied to clipboard");
  };

  return (
    <Box mx={4} my={2}>
      <Grid spacing={2} container>
        <Grid item xs={12} sm={12} md={12} lg={1}>
          <Typography variant="h5">Events</Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={2.3}>
          <DateRangePicker
            fullWidth
            onChange={handleDateChange}
            value={value}
            name="datepicker"
            onSubmit={getDeviceEventsData}
            disabled={deviceEventsMetaDataLoading}
          />
        </Grid>
        <Grid mb={2} item xs={12} sm={12} md={12} lg={2}>
          <Box>
            <Select
              onChange={({ target }) => {
                // @ts-ignore
                setEventType(target.value);
              }}
              size="small"
              fullWidth
              value={eventType}
              disabled={deviceEventsMetaDataLoading}
            >
              <MenuItem value={"PARSED"}>PARSED</MenuItem>
              <MenuItem value={"RAW"}>RAW</MenuItem>
            </Select>
          </Box>
        </Grid>
      </Grid>
      <Table
        columns={getDeviceEventsTableColumns(deviceEventsMetaData, eventType)}
        data={transformDeviceEventsDataForTable(deviceEventsMetaData)}
        progressPending={deviceEventsMetaDataLoading}
        cellActions={[
          {
            name: "View Info",
            onClick: handleViewInfo,
          },
        ]}
      />
      <Modal
        open={isModalOpen}
        onClose={onCloseModal}
        title="Info"
        primaryBtnProps={{
          type: "Click",
          text: "Confirm",
          // @ts-ignore
          onClick: onCloseModal,
        }}
        secondaryBtnProps={{ text: "Cancel", onClick: onCloseModal }}
        theme={theme}
      >
        <div>
          <Box display="flex" justifyContent="flex-end" mb={2}>
            <Button variant="text" size={"small"} onClick={handleCopy}>
              Copy
            </Button>
          </Box>
          <p>{rowJSON}</p>
        </div>
      </Modal>
    </Box>
  );
};

export default DeviceDetailsTables;
