import { types } from "@vilocnv/allsetra-core";
import {
  MapSvg,
  AlertSvg,
  ObjectsSvg,
  SubscriptionSvg,
  DevicesIcon,
  AccountsIcon,
  ObjectTypesIcon,
  ServicesIcon,
  FieldsIcon,
  DeviceTypesIcon,
  MapActiveIcon,
  DevicesActiveIcon,
  ObjectsActiveIcon,
  SubscriptionActiveIcon,
  DeviceTypesActiveIcon,
  AlertsActiveIcon,
  FieldsActiveIcon,
  ServiceActiveIcon,
  AccountActiveIcon,
  ObjectTypesActiveIcon,
  SubscriptionsIcon,
  SubscriptionsInactiveIcon,
  WhitelabelActiveIcon,
  WhitelabelInactiveIcon,
} from "assets/icons";

export const getDrawerMenuItems = (t: any): Array<types.IDrawerMenuItem> => [
  {
    id: "map",
    name: t("drawerMenuLinks.map"),
    icon: MapSvg,
    activeIcon: MapActiveIcon,
    path: "/dashboard",
  },
  {
    id: "alarm-desk",
    name: t("drawerMenuLinks.alarmDesk"),
    icon: AlertSvg,
    activeIcon: AlertsActiveIcon,
    path: "/dashboard/alarm-desk",
  },
  {
    id: "devices",
    name: t("drawerMenuLinks.devices"),
    icon: DevicesIcon,
    activeIcon: DevicesActiveIcon,
    path: "/dashboard/devices",
  },
  {
    id: "objects",
    name: t("drawerMenuLinks.objects"),
    icon: ObjectsSvg,
    activeIcon: ObjectsActiveIcon,
    path: "/dashboard/objects",
  },
  {
    id: "subscriptions",
    name: t("drawerMenuLinks.subscriptions"),
    icon: SubscriptionsInactiveIcon,
    activeIcon: SubscriptionsIcon,
    path: "/dashboard/subscriptions",
  },
];

export const getDrawerSubMenuLists = (): {
  [x: string]: Array<types.IDrawerMenuItem>;
} => ({
  Admin: [
    {
      id: "accounts-manager",
      name: "Account manager",
      icon: AccountsIcon,
      activeIcon: AccountActiveIcon,
      path: "/dashboard/account-manager",
    },
    {
      id: "object-types",
      name: "Object types",
      icon: ObjectTypesIcon,
      activeIcon: ObjectTypesActiveIcon,
      path: "/dashboard/object-types",
    },
    {
      id: "service-manager",
      name: "Service manager",
      icon: ServicesIcon,
      activeIcon: ServiceActiveIcon,
      path: "/dashboard/service-manager",
    },
    {
      id: "subscription-manager",
      name: "Subscription manager",
      icon: SubscriptionSvg,
      activeIcon: SubscriptionActiveIcon,
      path: "/dashboard/subscription-manager",
    },
    {
      id: "field-manager",
      name: "Field manager",
      icon: FieldsIcon,
      activeIcon: FieldsActiveIcon,
      path: "/dashboard/field-manager",
    },
    {
      id: "whitelabel-manager",
      name: "White label manager",
      icon: WhitelabelInactiveIcon,
      activeIcon: WhitelabelActiveIcon,
      path: "/dashboard/whitelabel-manager",
    },
  ],
  "Device Manager": [
    {
      id: "device-types",
      name: "Device types",
      icon: DeviceTypesIcon,
      activeIcon: DeviceTypesActiveIcon,
      path: "/dashboard/device-types",
    },
  ],
});

export const yesNoOptionsForDropdown = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
];

export const paymentTermInDaysOptions = [
  { days: 30, name: "1 month" },
  { days: 90, name: "3 months" },
  { days: 180, name: "6 month" },
];

export const alarmParams = {
  itemsPerPage: 1000,
  page: 1,
  orderBy: [
    {
      field: "id",
      ascending: false,
    },
  ],
};
