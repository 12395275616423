import moment from "moment";
import { TableColumn, Badge, types } from "@vilocnv/allsetra-core";
import { capitalize, isEmpty, round } from "lodash";
import { AccountPersonIcon, WarningIcon } from "assets/icons";
import { Stack, Typography } from "@mui/material";
import {
  Battery0Bar,
  LockOpenOutlined,
  LockOutlined,
  SensorsTwoTone,
  WarningRounded,
} from "@mui/icons-material";
import { checkValidDate } from "./accountsConstants";
import AlarmIcon from "assets/icons/common/AlarmIcon.png";

//
// TABLE HEADERS
//
export const ALL_ALARMS_TABLE_COLUMNS: TableColumn<types.IAlarm>[] = [
  {
    name: "Object Name",
    sortable: true,
    selector: (row: types.IAlarm) => (
      <>
        <div>{row?.object?.name}</div>
        <div>{`(${row?.object?.alarmOwner?.name})`}</div>
      </>
    ),
    wrap: true,
  },
  {
    name: "Object ID",
    sortable: true,
    selector: (row: types.IAlarm) => row?.aNumber || "N/A",
    minWidth: "120px",
    wrap: true,
  },
  {
    name: "Date",
    sortable: true,
    selector: (row: types.IAlarm) => (
      <>
        <div>
          {checkValidDate(row?.created)
            ? capitalize(moment(row?.created).fromNow())
            : "N/A"}
        </div>
        <div>
          {checkValidDate(row?.created)
            ? `(${moment(row?.created).format("MMMM DD, YYYY")}) at ${moment(
                row?.created
              ).format("HH:mm")}`
            : "N/A"}
        </div>
      </>
    ),
    minWidth: "170px",
  },
  {
    name: "Alarm Type",
    sortable: true,
    // @ts-ignore
    selector: (row: types.IAlarm) => row?.alarmType?.name || "N/A",
    width: "170px",
    wrap: true,
  },
  {
    name: "Object Status",
    sortable: true,
    minWidth: "180px",
    selector: (row: types.IAlarm) => {
      let hasImmobilizer, lastBatteryStatus;
      if (!isEmpty(row.object) && !isEmpty(row.object.metadata)) {
        for (let item of row.object.metadata) {
          // Field for the Immobilizer
          if (item.field.uniqueId === "2ff2c194-e66f-4e58-9e2e-d3ca605d1ee6") {
            hasImmobilizer = item.value;
          }

          // Field for the Last Battery Status
          if (item.field.uniqueId === "4a115888-3130-4a9f-abc0-0d3b85882af5") {
            lastBatteryStatus = item.value
              ? round(parseFloat(item.value), 2)
              : item.value;
          }
        }
      }

      return (
        <div
          style={{
            display: "flex",
          }}
        >
          <div>
            {row?.hasScmService && (
              <Badge
                textVariant="small"
                isRotate={false}
                icon={<WarningRounded />}
                colorScheme={"success"}
              >
                SCM
              </Badge>
            )}
            <Badge
              textVariant="small"
              isRotate={false}
              icon={hasImmobilizer ? <LockOpenOutlined /> : <LockOutlined />}
              colorScheme={hasImmobilizer ? "success" : "error"}
            >
              {hasImmobilizer ? "ON" : "OFF"}
            </Badge>
          </div>

          <div>
            <Badge
              icon={<SensorsTwoTone />}
              isRotate={false}
              textVariant="small"
              colorScheme={row?.ignitionStatus === 1 ? "success" : "error"}
            >
              {row?.ignitionStatus === 1 ? "ON" : "OFF"}
            </Badge>
            <Badge
              textVariant="small"
              icon={<Battery0Bar />}
              colorScheme={"info"}
            >
              {lastBatteryStatus || "N/A"}
            </Badge>
          </div>
        </div>
      );
    },
  },
];

export const ALARM_COMMENTS_TABLE_COLUMNS: TableColumn<any>[] = [
  {
    name: "Commenter",
    sortable: true,
    selector: (row: any) => (
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        ml={2}
        gap={2}
      >
        {row?.isPriority && <WarningIcon />}
        {row.isAccountComment && <AccountPersonIcon width={18} />}
        <Typography variant={"body2"}>{row?.commentedBy || "N/A"}</Typography>
      </Stack>
    ),
  },
  {
    name: "Date",
    sortable: true,
    selector: (row: any) => moment(row.date).format("MMMM DD, YYYY HH:mm"),
  },
  {
    name: "Note",
    wrap: true,
    sortable: true,
    selector: (row: any) => row.note,
  },
  {
    name: "Alarm Type",
    wrap: true,
    sortable: true,
    selector: (row: any) => row.alarmType?.name ?? "",
  },
];

export const ALARM_DELAY_DROPDOWN: any = [
  {
    name: "Custom",
    value: "Custom",
  },
  {
    name: "15 min",
    value: 15,
  },
  {
    name: "30 min",
    value: 30,
  },
  {
    name: "1h",
    value: 60,
  },
  {
    name: "2h",
    value: 120,
  },
  {
    name: "5h",
    value: 300,
  },
  {
    name: "12h",
    value: 720,
  },
  {
    name: "24h",
    value: 1440,
  },
];

export const ALARM_PRIORITY: any = [
  { id: 1, name: "1" },
  { id: 2, name: "2" },
  { id: 3, name: "3" },
  { id: 4, name: "4" },
  { id: 5, name: "5" },
  { id: 6, name: "6" },
  { id: 7, name: "7" },
  { id: 8, name: "8" },
  { id: 9, name: "9" },
];

export const LegendsList: any = [
  { text: "Ignition off", color: "#CC1010" },
  { text: "Ignition off >10km/h", color: "#E28E10" },
  { text: "Ignition on", color: "#148E20" },
  { text: "Offline", color: "#76828F" },
  { text: "Alarm", icon: AlarmIcon },
];
