import { FC } from "react";
import {
  ContentSectionLayout,
  TwoColsLayout,
  FormikInputField,
  Button,
} from "@vilocnv/allsetra-core";
import { Stack } from "@mui/material";
import { ChildFormBox } from "../ObjectSettingsForm.styled";

interface IProps {
  handleSubmit: () => void;
  resetForm: () => void;
  isValid: boolean;
  isSubmitting: boolean;
}

const CorrectionSetting: FC<IProps> = ({
  handleSubmit,
  resetForm,
  isValid,
  isSubmitting,
}) => {
  return (
    <ContentSectionLayout
      title="Correction"
      subTitle="Some text to help user understand what this block is responsible for."
    >
      <ChildFormBox>
        <TwoColsLayout hideDivider>
          <FormikInputField
            disabled={isSubmitting}
            type="number"
            label="New mileage"
            name="newMileage"
            fullWidth
          />
          <FormikInputField
            disabled={isSubmitting}
            label="Correction date"
            type={"datetime-local"}
            name="correctionDate"
            fullWidth
          />
        </TwoColsLayout>
        <Stack mt={3} direction={"row"} spacing={2}>
          <Button
            disabled={!isValid}
            onClick={handleSubmit}
            variant={"contained"}
            size={"small"}
            loading={isSubmitting}
          >
            Add correction
          </Button>
          <Button onClick={resetForm} variant={"text"} size={"small"}>
            Cancel
          </Button>
        </Stack>
      </ChildFormBox>
    </ContentSectionLayout>
  );
};

export default CorrectionSetting;
