import { FC, useState,useEffect } from "react";
import { Stack, useTheme } from "@mui/material";
import { FormikSelectField, Modal, ModalProps } from "@vilocnv/allsetra-core";
import { useFormikContext } from "formik";
import { IClearAlarm } from "app/data/types";
import { useAppDispatch, useAppSelector } from "hooks";
import { getCountryListThunk } from "app/features";
import { selectAlarmDeskState } from "app/data/selectors";

type Props = Omit<ModalProps, "title" | "children">;

const CountryWhiteListModal: FC<Props> = ({ open, onClose, ...rest }) => {
  const theme = useTheme();
  const { resetForm, isSubmitting, handleSubmit, isValid } =
    useFormikContext<IClearAlarm>();

  const dispatch = useAppDispatch();
  const { countryListData,countryListLoading } = useAppSelector(selectAlarmDeskState);


  useEffect(()=> {
   dispatch(getCountryListThunk());
   resetForm();
  },[]);

  const onCloseHandler = () => {
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onCloseHandler}
      title={"Add a new Country"}
      subTitle={" "}
      primaryBtnProps={{
        type: "submit",
        text: "Add Country",
        loading: isSubmitting,
        disabled: !isValid,
        // @ts-ignore
        onClick: handleSubmit,
      }}
      secondaryBtnProps={{
        text: "Cancel",
        onClick: onCloseHandler,
      }}
      theme={theme}
    >
      <Stack spacing={2}>
        <FormikSelectField
          label="Country name"
          name="countries"
          options={countryListData || []}
          optionLabelKey="name"
          optionValueKey="id"
          loading={countryListLoading}
          multiple
          searchable
        />
      </Stack>
    </Modal>
  );
};

export default CountryWhiteListModal;
